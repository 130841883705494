import React, { useEffect, useState } from 'react';
import gif1 from '../../assets/tasting1.gif';
import gif2 from '../../assets/tasting2.gif';
import gif3 from '../../assets/tasting3.gif';
import gif4 from '../../assets/tasting4.gif';

const Gif = () => {
  const [loaded, setLoaded] = useState(false);

  const gifs = [gif1, gif2, gif3, gif4];
  const gif = gifs[Math.floor(Math.random() * gifs.length)];

  useEffect(() => {
    setTimeout(() => setLoaded(true), 10);
  }, []);

  return (
    <div className={`gif${loaded ? ' loaded' : ''}`}>
      <img src={gif} alt="bonanza-gif" />
    </div>
  );
};

export default Gif;

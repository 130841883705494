import React, { useState, useEffect } from 'react';
import Winner from '../Winner/Winner';
import { useApp } from '../../contexts/AppContext';
import './Bonanza.scss';
import bonanzaPositions from './bonanzaPositions';
import Gif from './Gif';
import FlashyText from './FlashyText';

const Bonanza = () => {
  const initialDelay = 1000;
  const { winner, shuffledPeople } = useApp();
  const [delay, setDelay] = useState(initialDelay);
  const [number, setNumber] = useState(0);
  const [gif, setGif] = useState(false);
  const [done, setDone] = useState(false);
  const nonWinners = shuffledPeople.filter((person) => person.ID !== winner.ID);

  useEffect(() => {
    if (number >= nonWinners.length - 5 && !gif) setGif(true);
    if (number === nonWinners.length) {
      setDone(true);
      return;
    }

    setTimeout(() => {
      setNumber((prev) => prev + 1);
      setDelay((prev) => prev * 0.85);
    }, delay);
  }, [delay]);

  return (
    <div className="bonanza">
      { nonWinners.slice(0, number).map((person, i) => (
        <Winner winner={person} key={`bonanza-winner-${person.ID}`} style={bonanzaPositions[i]} delay={10} />
      )) }
      { gif && <Gif /> }
      { done && <FlashyText />}
    </div>
  );
};

export default Bonanza;

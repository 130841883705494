import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

const FlashyText = () => {
  const text = ['Vinsmaking!'];

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 1000);
  }, []);

  return (
    <div className={`everyone-wins${loaded ? ' loaded' : ''}`}>
      { text.map((word) => (
        <div key={uuid()}>
          { word.split('').map((letter) => (
            <span key={uuid()}>{letter !== ' ' && letter}</span>
          )) }
        </div>
      )) }
    </div>
  );
};

export default FlashyText;

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Person from '../Person/Person';
import './Winner.scss';

const Winner = ({
  winner,
  prerevealed,
  style,
  delay,
}) => {
  const [revealed, setRevealed] = useState(prerevealed);

  useEffect(() => {
    setTimeout(() => setRevealed(true), delay);
  }, []);

  return (
    <div className={revealed ? 'winner revealed' : 'winner'} style={style}>
      <Person person={winner} />
    </div>
  );
};

Winner.propTypes = {
  winner: PropTypes.shape({
    post_title: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
  prerevealed: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  delay: PropTypes.number,
};

Winner.defaultProps = {
  prerevealed: false,
  style: {},
  delay: 0,
};

export default Winner;

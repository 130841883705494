import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import './Person.scss';

const Person = forwardRef(({ person, onClick, disabled }, winner) => {
  const { post_title: name } = person;
  let { image } = person;

  if (!image) { image = 'https://www.headspin.no/wp-content/themes/headspin/images/missing_img.png'; }

  const handleKeyPress = (e) => {
    if (e.code === 'Space') onClick(e);
  };

  return (
    <div
      className={`person${disabled ? ' disabled' : ''}`}
      ref={winner}
      onClick={onClick}
      onKeyPress={handleKeyPress}
      role="menuitemcheckbox"
      aria-checked={!disabled}
      tabIndex={0}
    >
      <img
        className="person__image"
        src={image}
        alt=""
        width="200"
        height="300"
      />
      <h4 className="person__name">{name}</h4>
    </div>
  );
});

Person.propTypes = {
  person: PropTypes.shape({
    post_title: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Person.defaultProps = {
  onClick: null,
  disabled: false,
};

export default Person;

import React from 'react';
import { v4 as uuid } from 'uuid';
import { useApp } from '../../contexts/AppContext';
import Button from '../Button/Button';
import Person from '../Person/Person';
import './Settings.scss';

const Settings = () => {
  const {
    people, setShowSettings, disabledPeople, setDisabledPeople,
  } = useApp();

  const togglePerson = (id) => {
    if (disabledPeople.indexOf(id) === -1) {
      setDisabledPeople([...disabledPeople, id]);
    } else {
      setDisabledPeople(disabledPeople.filter((a) => a !== id));
    }
  };

  const buttonClickHandler = () => {
    // Go fullscreen
    const doc = document.documentElement;
    if (doc.requestFullscreen) {
      doc.requestFullscreen();
    } else if (doc.webkitRequestFullscreen) { // Safari
      doc.webkitRequestFullscreen();
    } else if (doc.msRequestFullscreen) { // IE11
      doc.msRequestFullscreen();
    }

    setShowSettings(false);
  };

  return (
    <div className="settings">
      <h3 className="settings__heading">Velg hvem som skal være med i trekningen</h3>
      <div className="settings__people-container">
        { people.map((person) => (
          <Person
            person={person}
            disabled={disabledPeople.indexOf(person.ID) > -1}
            onClick={() => togglePerson(person.ID)}
            key={uuid()}
          />
        )) }
      </div>
      <footer className="settings__footer">
        <Button
          onClick={buttonClickHandler}
          disabled={people.filter((p) => disabledPeople.indexOf(p.ID) === -1).length === 0}
        >
          Fortsett
        </Button>
      </footer>
    </div>
  );
};

export default Settings;

import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

const SettingsContext = createContext();

export function useSettings() {
  return useContext(SettingsContext);
}

const SettingsProvider = ({ children }) => {
  const [enabledPeople, setEnabledPeople] = useState([]);
  const [spinDuration, setSpinDuration] = useState(14800);
  const [easeInFactor, setEaseInFactor] = useState(0.1);
  const [easeOutFactor, setEaseOutFactor] = useState(0.99);

  const value = {
    enabledPeople,
    setEnabledPeople,
    spinDuration,
    setSpinDuration,
    easeInFactor,
    setEaseInFactor,
    easeOutFactor,
    setEaseOutFactor,
  };

  return (
    <SettingsContext.Provider value={value}>
      { children }
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SettingsProvider };

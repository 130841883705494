export const shuffleArray = (array) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

export function* uniqueId() {
  let id = 0;

  while (true) {
    id += 1;
    yield id;
  }
}

/**
 * Check if the passed day is today.
 *
 * @param    {string|number}  day
 * The date to check. Can contain up to three parts (date, month and year) in that order.
 * If you're only checking the date, an integer can be passed, otherwise, a string is required.
 * The date must be formatted with any separator. The year must be four digit full year.
 * Examples of valid parameters: `10` `"10.11"` `"10-11-2021"` `"10th 11-2021"`
 * @returns  {boolean} True if the given date is today
 */
export const isToday = (day) => {
  const dateParts = day.toString().split(/[^\d]+/);
  const today = new Date();
  const todayParts = [
    today.getDate(),
    today.getMonth() + 1,
    today.getFullYear(),
  ];

  return dateParts.slice(0, 3).every(
    (datePart, index) => parseInt(datePart, 10) === todayParts[index],
  );
};

export default {
  shuffleArray,
  uniqueId,
  isToday,
};

import PropTypes from 'prop-types';
import {
  createContext, useContext, useEffect, useState,
} from 'react';
import music from '../assets/music_and_sounds.mp3';
import bonanzaMusic from '../assets/bonanza.mp3';

const MusicContext = createContext();

export function useMusic() {
  return useContext(MusicContext);
}

const MusicProvider = ({ children }) => {
  const [audio] = useState(new Audio(music));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    audio[playing ? 'play' : 'pause']();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  const rewind = () => {
    setPlaying(false);
    audio.currentTime = 0;
  };

  const setBonanzaMusic = (set = true) => {
    audio.src = set ? bonanzaMusic : music;
  };

  const value = {
    audio,
    playing,
    setPlaying,
    toggle,
    rewind,
    setBonanzaMusic,
  };

  return (
    <MusicContext.Provider value={value}>
      { children }
    </MusicContext.Provider>
  );
};

MusicProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MusicProvider };

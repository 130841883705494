const bonanzaPositions = [
  { left: '87.76353924909591%', top: '57.39212230094299%' },
  { left: '35.13151057840349%', top: '54.76132704965361%' },
  { left: '37.88802588851805%', top: '52.93164675954745%' },
  { left: '18.042372491245892%', top: '70.78323707306303%' },
  { left: '59.94424944143401%', top: '73.19662410350134%' },
  { left: '40.983858665640575%', top: '58.94631664762741%' },
  { left: '26.63286835446599%', top: '49.176449397773695%' },
  { left: '64.73681733466566%', top: '75.340542769458%' },
  { left: '53.56092249553643%', top: '22.53302044793696%' },
  { left: '67.94085429595914%', top: '39.125844775638974%' },
  { left: '83.96704170050879%', top: '62.26064054073041%' },
  { left: '80.26099437424104%', top: '43.29134446283846%' },
  { left: '29.954145956747738%', top: '74.06135183732212%' },
  { left: '50.39985927303207%', top: '67.47487733933215%' },
  { left: '15.576845188682578%', top: '28.739345484543517%' },
  { left: '47.748725147284%', top: '35.72819998639545%' },
  { left: '52.74348946762902%', top: '51.014713022076975%' },
  { left: '27.621077906079385%', top: '74.57285375170298%' },
  { left: '70.31419056366357%', top: '73.72850472312993%' },
  { left: '42.204825022505034%', top: '74.58551195285091%' },
  { left: '23.369089328161294%', top: '77.59501805523533%' },
  { left: '42.521495660418786%', top: '63.709929266606636%' },
  { left: '39.973572771442186%', top: '55.77397928535652%' },
  { left: '19.987179993030743%', top: '33.34423753191018%' },
  { left: '43.726443930601995%', top: '55.5991394414399%' },
  { left: '21.623034090849586%', top: '70.84227713979044%' },
  { left: '15.73523749360989%', top: '68.47167438668066%' },
  { left: '88.28710514596905%', top: '45.790464030240564%' },
  { left: '42.7502660243056%', top: '32.08592420544225%' },
  { left: '57.24076603926206%', top: '70.76933950344352%' },
  { left: '57.602073695498675%', top: '57.17519780887594%' },
  { left: '35.63301768920293%', top: '23.692779184080962%' },
  { left: '41.98612572669152%', top: '36.820406914062346%' },
  { left: '30.21601955564084%', top: '27.242477067735%' },
  { left: '15.615073700020003%', top: '58.162815438221074%' },
  { left: '64.85580148082087%', top: '41.670348107795164%' },
  { left: '89.30304587071358%', top: '42.94547494265797%' },
  { left: '35.486039785319036%', top: '74.0149198142287%' },
  { left: '55.23822915379171%', top: '43.454976749764846%' },
  { left: '62.39354166960452%', top: '70.66542610995117%' },
  { left: '27.154424155152714%', top: '40.00775793685135%' },
  { left: '79.74577135264242%', top: '47.12601901984442%' },
  { left: '42.15988422607132%', top: '35.58424122974897%' },
  { left: '17.260908665247%', top: '61.02456526000679%' },
  { left: '19.07065772623767%', top: '34.894073774012405%' },
  { left: '68.5397937623171%', top: '22.42792337529262%' },
  { left: '77.5734216594825%', top: '64.88775654936464%' },
  { left: '56.7316623784475%', top: '53.90817736681286%' },
  { left: '11.666932359363491%', top: '57.678667467355844%' },
  { left: '16.922079928343734%', top: '78.84262920026526%' },
];

export default bonanzaPositions;

import { useEffect, useState } from 'react';
import { IoReloadCircleSharp } from 'react-icons/io5';
import { useApp } from '../../contexts/AppContext';
import './ResetButton.scss';

const ResetButton = () => {
  const { reset } = useApp();
  const [buttonLoaded, setButtonLoaded] = useState(false);

  const keyPressHandler = (e) => {
    if (e.code === 'Space') {
      reset();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setButtonLoaded(true);
    }, 2000);
  }, []);

  return (
    <div
      className={`reset-button${buttonLoaded ? ' loaded' : ''}`}
      onClick={reset}
      onKeyPress={keyPressHandler}
      role="button"
      tabIndex={0}
    >
      <IoReloadCircleSharp />
    </div>
  );
};

export default ResetButton;

import { useState } from 'react';
import useLocalStorage from './useLocalStorage';

const useStateWithLocalStorage = (key, initialValue) => {
  const [lsValue, setLsValue] = useLocalStorage(key, initialValue);
  const [stateValue, setStateValue] = useState(lsValue);

  const setValue = (newValue) => {
    setLsValue(newValue);
    setStateValue(newValue);
  };

  return [stateValue, setValue];
};

export default useStateWithLocalStorage;

import { CircleLoader } from 'react-spinners';
import PeopleList from './components/PeopleList/PeopleList';
import Button from './components/Button/Button';
import './styles.scss';
import { useApp } from './contexts/AppContext';
import Settings from './components/Settings/Settings';
import WinnerScreen from './components/WinnerScreen/WinnerScreen';
import Bonanza from './components/Bonanza/Bonanza';

export default function App() {
  const {
    loading,
    fetchError,
    finished,
    showSettings,
    spinning,
    setSpinning,
  } = useApp();

  const { winner } = useApp();

  const spinClickHandler = () => {
    setSpinning(true);
  };

  return (
    <div className="App">
      { loading && !fetchError && <CircleLoader color="#d4f" size="80" /> }
      { !loading && !fetchError && showSettings && <Settings /> }
      { !loading && !fetchError && !showSettings && (
        <>
          <PeopleList blured={finished} />
          <Button disabled={spinning} onClick={spinClickHandler}>Spin</Button>
          { finished && <WinnerScreen /> }
          { finished && winner.id === 0 && <Bonanza /> }
        </>
      )}
    </div>
  );
}

import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import useStateWithLocalStorage from '../hooks/useStateWithLocalStorage';
import { shuffleArray } from '../utils/helpers';
import { useMusic } from './MusicContext';
import gold from '../assets/gold.jpg';

const AppContext = createContext();

export function useApp() {
  return useContext(AppContext);
}

const AppProvider = ({ children }) => {
  const { setPlaying, rewind, setBonanzaMusic } = useMusic();

  const [disabledPeople, setDisabledPeople] = useStateWithLocalStorage('disabledPeople', []);
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const [shuffledPeople, setShuffledPeople] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [showSettings, setShowSettings] = useState(true);
  const [winner, setWinner] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [finished, setFinished] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const reset = () => {
    rewind();
    setSpinning(false);
    setFinished(false);
    setIsReset(true);
  };

  useEffect(() => fetch('https://www.headspin.no/wp-admin/admin-ajax.php?action=get_employees')
    .then((response) => response.json())
    .then((fetchedPeople) => {
      const sortedPeople = fetchedPeople.sort((a, b) => {
        if (a.post_title < b.post_title) {
          return -1;
        }
        return (a.post_title > b.post_title) ? 1 : 0;
      });
      setPeople([...sortedPeople, {
        id: 0,
        post_title: '',
        image: gold,
      }]);
      setLoading(false);
    })
    .catch((error) => {
      setFetchError(error);
      setLoading(false);
    }), []);

  useEffect(() => {
    if (!people || people?.length === 0) return;

    if (isReset) setIsReset(false);

    const shuffledPeopleArray = shuffleArray(people.filter(
      (p) => disabledPeople.indexOf(p.ID) === -1,
    ));
    setShuffledPeople(shuffledPeopleArray);

    if (shuffledPeopleArray.length === 0) {
      setPeopleList([]);
    } else {
      const randomAmount = Math.floor(Math.random() * 40) + 100;
      let peopleArray = [...shuffledPeopleArray];
      while (peopleArray.length < randomAmount) {
        peopleArray = [...peopleArray, ...shuffledPeopleArray];
      }

      setPeopleList(peopleArray.slice(0, randomAmount));
    }
  }, [people, disabledPeople, isReset]);

  useEffect(() => {
    setPlaying(spinning);
  }, [spinning]);

  useEffect(() => {
    setBonanzaMusic(winner?.id === 0);
  }, [winner]);

  const value = {
    loading,
    setLoading,
    people,
    setPeople,
    shuffledPeople,
    peopleList,
    setPeopleList,
    disabledPeople,
    setDisabledPeople,
    showSettings,
    setShowSettings,
    winner,
    setWinner,
    fetchError,
    setFetchError,
    finished,
    setFinished,
    spinning,
    setSpinning,
    isReset,
    setIsReset,
    reset,
  };

  return (
    <AppContext.Provider value={value}>
      { children }
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProvider };

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { AppProvider } from './contexts/AppContext';
import { MusicProvider } from './contexts/MusicContext';
import { SettingsProvider } from './contexts/SettingsContext';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <StrictMode>
    <SettingsProvider>
      <MusicProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </MusicProvider>
    </SettingsProvider>
  </StrictMode>,
  rootElement,
);

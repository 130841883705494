import React from 'react';
import Confetti from 'react-confetti';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import { useWindowSize } from 'react-use';
import ResetButton from '../ResetButton/ResetButton';
import Winner from '../Winner/Winner';
import { useApp } from '../../contexts/AppContext';
import './WinnerScreen.scss';

const WinnerScreen = () => {
  const { winner } = useApp();
  const { width, height } = useWindowSize();

  return (
    <>
      <ResetButton />
      <div className="confetti-container">
        <ConfettiExplosion
          floorHeight={height * 2}
          floorWidth={width * 2}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
          }}
        />
      </div>
      <Winner winner={winner} />
      <Confetti
        width={width}
        height={height}
        colors={[
          '#FFD700',
          '#fcc200',
          '#caa906',
          '#da9100',
          '#c0c0c0',
          '#d3d3d3',
        ]}
      />
    </>
  );
};

export default WinnerScreen;

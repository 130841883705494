import PropTypes from 'prop-types';
import { React } from 'react';
import './Button.scss';

const Button = ({ onClick, disabled, children }) => (
  <button type="button" className="button" onClick={onClick} disabled={disabled}>
    { children }
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  onClick: null,
  disabled: false,
  children: null,
};

export default Button;

import PropTypes from 'prop-types';
import easyScroll from 'easy-scroll';
import { useEffect, useRef } from 'react';
import { useApp } from '../../contexts/AppContext';
import { useSettings } from '../../contexts/SettingsContext';
import Person from '../Person/Person';
import './PeopleList.scss';
import { uniqueId } from '../../utils/helpers';

const PeopleList = ({ blured }) => {
  const {
    peopleList, setWinner, spinning, setFinished, isReset,
  } = useApp();
  const { spinDuration, easeInFactor, easeOutFactor } = useSettings();

  const winnerEl = useRef(null);
  const listEl = useRef(null);

  useEffect(() => {
    if (!spinning) return;

    const scrollAmount = winnerEl.current.offsetLeft
      + winnerEl.current.offsetWidth / 2
      - window.innerWidth / 2
      - 8;

    easyScroll({
      scrollableDomEle: listEl.current,
      direction: 'right',
      duration: spinDuration * easeInFactor,
      easingPreset: 'easeInQuint',
      scrollAmount: scrollAmount * easeInFactor,
      onAnimationCompleteCallback: () => {
        easyScroll({
          scrollableDomEle: listEl.current,
          direction: 'right',
          duration: spinDuration * (1 - easeInFactor - easeOutFactor),
          cubicBezierPoints: {
            x1: 0.25,
            y1: 0.65,
            x2: 0.75,
            y2: 0.35,
          },
          scrollAmount: scrollAmount * (1 - easeInFactor - easeOutFactor),
          onAnimationCompleteCallback: () => {
            easyScroll({
              scrollableDomEle: listEl.current,
              direction: 'right',
              duration: spinDuration * easeOutFactor,
              easingPreset: 'easeOutQuint',
              scrollAmount: scrollAmount * easeOutFactor,
            });
          },
        });
      },
    });

    setTimeout(() => setFinished(true), spinDuration * 0.95);
  }, [spinning]);

  useEffect(() => {
    listEl.current.scrollLeft = 0;
  }, [isReset]);

  const idGen = uniqueId();

  return (
    <div className={`people-list${blured ? ' blured' : ''}`}>
      <div className="people-list__wrapper" ref={listEl}>
        {peopleList && peopleList.length ? (
          peopleList.map((person, i) => {
            const isWinner = i === peopleList.length - 10;
            if (isWinner) setWinner(person); // TODO: Fix bad setState call (see console error)
            return (
              <Person
                person={person}
                key={`${idGen.next().value}_${person.ID}`}
                ref={isWinner ? winnerEl : null}
              />
            );
          })
        ) : (
          <h3>Loading people...</h3>
        )}
      </div>
      <div className="people-list__cursor" />
    </div>
  );
};

PeopleList.propTypes = {
  blured: PropTypes.bool,
};

PeopleList.defaultProps = {
  blured: false,
};

export default PeopleList;
